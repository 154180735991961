export const Enviroment = {

    url_apidian: 'https://apidian.adentlab.com.co:90/api/ubl2.1/',
    url_dtDental: 'https://test-api.dtdental.co/api/v1/',

    url_backend: 'https://api-rapifactura.adentlab.com.co/api',
    
    //url_backend: 'http://localhost:5005',
    //url_socket: 'http://localhost:5005/',


    mapPK: 'pk.eyJ1IjoiYWRlbnRsYWJzb2x1Y2lvbiIsImEiOiJjbDBjNXR6aWMwaG1zM3BuYjd0eDYyOGw5In0.4mUuTY55LqyZNSC_u9A9KQ'

}