import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Enviroment } from './env';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketWebService {

  socket: any;
  readonly uri: string =""

  constructor() {
    this.socket = io(this.uri, { transports: ['websocket','polling', 'flashsocket']});
  }

  listen(eventName: string) {
    return new Observable((Subscriber) => {
      this.socket.on(eventName, (data) => {
        Subscriber.next(data);
      })
    })
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }


}

